<script>
import Vue from "vue";
import api from '@/helpers/apirest/api';

export default {
  emits: ['onSuccess'],
  props: {
    orderIntegration:{
      type: Object,
      default: null
    }
  },
  components: {
  },
  methods: {
    async resync(){
      try {

        const result = await Vue.swal({
          title: "Reintentar sincornización",
          icon: "info",
          html:  "Seguro que desea reintentar el envío?",
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sí',
          cancelButtonText: 'Cancelar',
        });

        if (result.value) {
          await api.put({
              url: `orders/${this.orderIntegration?.order_id}/order-integrations/${this.orderIntegration?.id}/resync`,
              config: {
                  withLoading: true
              }                
          });

          this.$emit('onSuccess');

          Vue.swal({
              position: "top-end",
              icon: "success",
              title: "Acción ejecutada con exito",
              showConfirmButton: false,
              timer: 2000
          });

        }
        
      }
      catch (error) {
          console.log(error);
      }
    }
  }
};
</script>

<template>
  <div v-if="orderIntegration?.id && orderIntegration?.status == 'failed'">
    <i 
      class="mdi mdi-sync-circle text-primary pointer"
      :title="'Click para intentar el envío nuevamente'"
      v-b-tooltip.hover
      @click="resync()"
    ></i>
  </div>    
</template>
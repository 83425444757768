<script>
import Modal from "@/components/modals/modal-form.vue";
import api from '@/helpers/apirest/api';

import Prism from 'prismjs';
import 'prismjs/components/prism-json';  // Importa el soporte para JSON
import 'prismjs/themes/prism.css';       // Tema para el resaltado

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return {
            failedRequest:null,
            formModalId:'order-form-modal-show',   
            webUrl: process.env.VUE_APP_WEB_URL,        
        }
    },
    components: {
        Modal
    },
    mounted() {
        
    },
    computed: {
        modalTilte(){
            return `Detalle de error (${this.failedRequest?.error_title || ''})`;
        },
    },
    methods: {
        highlightCode() {
            Prism.highlightElement(this.$refs.request_headers);
            Prism.highlightElement(this.$refs.request_body);
            Prism.highlightElement(this.$refs.response_headers);
            Prism.highlightElement(this.$refs.response_body);
        },
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `failed-requests/${this.id}`
                });
                this.failedRequest = response?.data?.data;
                setTimeout(() => {
                    this.highlightCode();                    
                }, 1000);
            }
        },
        shown() {
            this.getData();            
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        }
    }
};
</script>

<template>
    <div>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Close'">
            <div class="row mb-4">
                <div class="col-md-12">
                    <div class="card h-100">
                        <div class="card-body">
                            <div><span class="text-muted">URL: </span><span>{{ failedRequest?.url }}</span></div>
                            <div><span class="text-muted">Método: </span><span>{{ failedRequest?.method }}</span></div>
                            <div><span class="text-muted">Código de estado: </span><span>{{ failedRequest?.status_code }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-6">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Request headers</h4>
                            <pre :class="`language-json`">
                                <code ref="request_headers">
                                    {{ failedRequest?.request_headers }}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Request body</h4>
                            <pre :class="`language-json`">
                                <code ref="request_body">
                                    {{ failedRequest?.request_body }}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Response headers</h4>
                            <pre :class="`language-json`">
                                <code ref="response_headers">
                                    {{ failedRequest?.response_headers }}
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title mb-4">Response body</h4>
                            <pre :class="`language-json`">
                                <code ref="response_body">{{ failedRequest?.response_body }}</code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>    
</template>
<script>
import QlkFailedRequet from '@/components/widgets/qlk-failed-requet.vue';

export default {
  props: {
    id: Number
  },
  components: {
      QlkFailedRequet
  },
  methods: {
    showViewModal() {
      this.$bvModal.show('failed-request-modal-show-' + this.id);
    },
  }
};
</script>

<template>
  <div v-if="id">
    <QlkFailedRequet :id="id" :modalId="'failed-request-modal-show-' + id"></QlkFailedRequet>
    <i 
      class="mdi mdi-information-outline text-danger pointer"
      :title="'Click para ver detalle del error '"
      v-b-tooltip.hover
      @click="showViewModal()"
    ></i>
  </div>    
</template>